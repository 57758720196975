export const primary = "#ffb5b5";
export const secondary = "#132743";
export const secondaryLight = "#1a2f3a";
export const accentOne = "#407088";
export const accentTwo = "#ffcbcb";
export const red = "#e94c74";
export const green = "#00D9CD";
export const turqoise = "#309fa2";
export const yellow = "#FFB902";
export const orange = "#ffa604";

export const gray = "#c6c6c6";
export const blueGray = "#F3F5F9";
export const black = "#040517";
